.list-card{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.list-title{
    display:flex;
    justify-content: space-between;
}

.list-button-group{
    display: flex;
    justify-content: space-between;
    margin-top: .5rem !important;
    margin-bottom: .5rem;
}

.delete-list-button{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border: 0px;
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.delete-list-button:hover{
    background-color: #bb2d3b;
    border-color: #b02a37;
}

.edit-list-button{
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    border: 0px;
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}
.edit-list-button:hover{
    background-color: #0b5ed7;
    border-color: #0a58ca;
}