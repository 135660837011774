:root{
    --light-gray: #6c757d;
    --grey: #555;
    --orange: #f28500;
    --white: #f5f5f5;
    --green: #50C763;
    --blue: #0077CC;
    --dark-blue: #0066AF;
    --light-blue: #BCDFFB;
    --black: #28282B;
    --red: #DA4A4A;
}

.vh20{
    height: 30vh !important;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

p {
    margin: 0 !important;
}

.ag-popup {
    height: 0 !important;
    min-height: 0 !important;
}

.btn-primary {
    --bs-btn-color: var(--white) !important;
    --bs-btn-bg: var(--blue) !important;
    --bs-btn-border-color: var(--blue) !important;
    --bs-btn-hover-color: var(--white) !important;
    --bs-btn-hover-bg: var(--dark-blue) !important;
    --bs-btn-hover-border-color: var(--dark-blue) !important;
    --bs-btn-active-color: var(--white) !important;
    --bs-btn-active-bg: var(--dark-blue) !important;
    --bs-btn-active-border-color: var(--dark-blue) !important;
    --bs-btn-disabled-color: var(--white) !important;
    --bs-btn-disabled-bg: var(--blue) !important;
    --bs-btn-disabled-border-color: var(--blue) !important;
}

@font-face {
    font-family:Avenir;
    font-style:normal;
    font-weight:400;
    font-display:swap;
    src:url(https://fonts.gstatic.com/l/font?kit=ijwUs5PnXdA4ZZUA55d7&skey=8887f17a62203d44&v=v4) format(truetype);
    }

.Select-value-label,.Select-option {
    white-space:pre-wrap!important;
    }
    
    .advanced-search {
    grid-column:1 / span 3;
    grid-row:1;
    }
    
    .advanced-search-button {
    grid-row:3;
    grid-column:2;
    }
    
    .alert {
    margin:0!important;
    padding:.375rem .75rem!important;
    }
    
    .align-flex-end {
    display:flex;
    align-items:flex-end;
    }
    
    .all-branches-wrapper,.full-width,.all-branches-wrapper {
    width:100%;
    }
    
    .assignment-button-group {
    align-items:center;
    justify-content:center;
    display:flex;
    }
    
    .badge {
    margin-left:.5rem;
    }
    
    .blue-btn {
    color:#fff!important;
    background:rgba(123765/1)!important;
    border-radius:.25em;
    font-size:1.2rem;
    margin:1em 0;
    padding:.5rem 2rem;
    }
    
    .blue-btn:hover {
    background:rgba(165200227/1)!important;
    }
    
    .border-bottom {
    border-bottom:1px solid #e5e5e5;
    }
    
    .border-right {
    border-right:.2rem solid #5999f6!important;
    }
    
    .border-top {
    border-top:1px solid #e5e5e5;
    }
    
    .box-shadow {
    box-shadow:0 .25rem .75rem rgba(0,0,0,.05);
    }
    
    .btn-group>.btn-check:checked+.btn {
    z-index:0!important;
    }
    
    .btn-primary {
    color:#fff;
    background-color:#1b6ec2;
    border-color:#1861ac;
    }
    
    .candidate-dashboard-grey-outline li {
    border:1px solid rgb(196, 196, 196);
    border-radius:.25rem;
    }

    .vh40{
        height: 40vh !important;
    }
    
    .candidate-modal-grid,.grid {
    height:65vh;
    width:100%;
    }
    
    .center-items {
    display:flex;
    justify-content:center;
    align-items:center;
    }

    .email-form-wrapper{
        height: 100%;
    }
    
    .center-submit-button {
    justify-content:center;
    }
    
    .app-container {
    width:100vw!important;
    padding:0 2vw 2vw!important;
    }
    
    .download-button-wrapper {
    display:flex;
    justify-content:flex-end;
    }
    
    .dropdown-item {
    color:#000!important;
    }
    
    .example-enter {
    opacity:0.01;
    }
    
    .example-enter.example-enter-active {
    opacity:1;
    transition:opacity 500ms ease-in;
    }
    
    .example-leave {
    opacity:1;
    }
    
    .example-leave.example-leave-active {
    opacity:0.01;
    transition:opacity 300ms ease-in;
    }
    
    .filter-branches,.r2-c1 {
    grid-row:2;
    grid-column:1;
    }
    
    .filter-grid {
    grid-row:5;
    grid-column:1/3;
    }
    
    .filter-menu {
    grid-row:4;
    grid-column:1;
    }
    
    .filter-messages {
    grid-row:3;
    grid-column:1;
    margin:0!important;
    }
    
    .filter-wrapper {
    display:grid;
    grid-template-columns:1fr 1fr;
    gap:10px;
    }
    
    .footer {
    position:absolute;
    width:100%;
    white-space:nowrap;
    background:#052540;
    color:#fff!important;
    }
    
    .full-height {
    height:100%;
    }
    
    .full-width {
    width:100%!important;
    }
    
    .gray-btn {
    color:#444!important;
    background:rgba(240240240/1)!important;
    border-radius:.25em;
    font-size:1.2rem;
    margin:1em 0;
    padding:.5rem 2rem;
    }
    
    .gray-btn:hover {
    color:#444!important;
    background:rgba(220220220/1)!important;
    }
    
    .grid-100w {
    height:70vh;
    width:100%;
    }
    
    .grid-center {
    display:flex;
    justify-content:center;
    align-items:flex-end;
    }
    
    .grid-download {
    grid-row:4;
    grid-column:2;
    }
    
    .hidden {
    display:none!important;
    }
    
    .lb-btn {
    color:#fff!important;
    background:rgba(165200227/1)!important;
    border-radius:.25em;
    }
    
    .lb-btn:hover {
    color:#fff!important;
    background:rgba(123765/1)!important;
    }
    
    .linked-cell {
    text-decoration:underline;
    cursor:pointer;
    color: rgb(106, 205, 238);
    }
    
    .loading {
    border:16px solid #f3f3f3;
    border-top:16px solid #3498db;
    border-radius:50%;
    width:130px;
    height:130px;
    animation:spin 2s linear infinite;
    }
    
    .login-btn,.or-btn {
    color:#fff!important;
    background:rgba(23412839/1)!important;
    border-radius:.25em;
    border:none;
    }
    
    .login-btn:hover,.or-btn:hover {
    color:#ea8027!important;
    background:rgba(23412839/0.35)!important;
    }
    
    .margin-bottom {
    margin-bottom:1rem;
    }
    
    .margin-right {
    margin-right:.5rem;
    }
    
    .margin-top {
    margin-top:.5rem!important;
    }
    
    .modal-70w {
    height:50vh!important;
    max-width:none!important;
    width:70%!important;
    }
    
    .modal-90w {
    max-width:90vw!important;
    }
    
    .modal-content {
    padding:1rem;
    }
    
    .modal-grid {
    height:80vh;
    width:100%;
    }
    
    .no-padding {
    padding:0!important;
    }
    
    .r1-c1 {
    grid-row:1;
    grid-column:1;
    }
    
    .r1-c2 {
    grid-row:1;
    grid-column:2;
    }
    
    .r1-c3 {
    grid-row:1;
    grid-column:3;
    }
    
    .read-only-job-description {
    height:20vh;
    overflow:scroll;
    }
    
    .release-notes {
    height:60vh;
    overflow:scroll;
    }
    
    .sixty-percent-width {
    flex:2!important;
    }
    
    .text-center {
    text-align:center;
    }
    
    .text-right {
    text-align:right;
    }
    
    .type-to-search-option {
    text-align:left;
    color:#000;
    }
    
    .white-box {
    border-radius:2rem;
    background:rgba(255255255.75);
    box-shadow:0 .25rem .75rem rgba(0,0,0,.07);
    margin:4rem;
    padding:2rem;
    }
    
    body {
    font-family:Avenir!important;
    color:#333;
    margin-bottom:145px;
    background-image:url(./Images/people-bg.jpg);
    background-position:center;
    background-repeat:no-repeat;
    background-size:cover;
    }
    
    button.accept-policy {
    font-size:1rem;
    line-height:inherit;
    }
    
    footer a.nav-link {
    color:#fff!important;
    }
    
    h1 {
    font-size:3rem!important;
    }
    
    h1,h2,h3,h4,h5,h6 {
    font-family:Roboto, sans-serif!important;
    color:#052540;
    }
    
    header .container {
    padding:.25em!important;
    }
    
    header a.nav-link {
    color:#052540!important;
    }
    
    header a.nav-link:hover,a.nav-link:active,footer a.nav-link:hover,a.nav-link:active {
    color:#a0c8e2!important;
    }
    
    html {
    font-size:14px !important;
    position:relative;
    min-height:100%;
    }

    
    .red-text {
        color:red;
    } 
    
    @media (min-width: 768px){
    .advanced-search {
    grid-column:1 / span 3;
    grid-row:1;
    }
    
    .advanced-search-button {
    grid-row:3;
    grid-column:3;
    }
    
    .filter-branches,.r2-c1 {
    grid-row:2;
    grid-column:1;
    }
    
    .filter-grid {
    grid-row:4;
    grid-column:1/4;
    }
    
    .filter-menu {
    grid-row:3;
    grid-column:1;
    }
    
    .filter-messages {
    grid-row:2;
    grid-column:2;
    }
    
    .filter-wrapper {
    display:grid;
    grid-template-columns:1fr 1fr .5fr;
    gap:10px;
    }
    
    .grid-download {
    grid-row:2;
    grid-column:3;
    }
    
    .r1-c1 {
    grid-row:1;
    grid-column:1;
    }
    
    .r1-c2 {
    grid-row:1;
    grid-column:2;
    }
    
    .r1-c3 {
    grid-row:1;
    grid-column:3;
    }
    
    html {
    font-size: 14px !important;
    min-height:100%;
    position:relative;
    }
    }

    .ag-theme-alpine {
        --ag-font-size: 12px !important;
        --ag-borders: none !important;
        --ag-header-background-color: #f0f0f08e;
        --ag-header-height: 40px;
        --ag-odd-row-background-color: #f0f0f08e;
        --ag-row-hover-color: #4585fca9;
        --ag-checkbox-unchecked-color: gray;
        --ag-checkbox-checked-color: blue;
        --ag-selected-row-background-color: rgba(0, 110, 255, 0.144);
        --ag-row-height: 30px !important;
        width: 100% !important;
    }

    @media screen and (width < 1280px) {
        .ag-theme-alpine{
            height: 42vh;
        }
    }

    @media screen and (1600px > width >= 1280px ) {
        .ag-theme-alpine{
            height: 60vh;
        }
    }

    @media screen and (width >= 1600px) {
        .ag-theme-alpine{
            height: 70vh;
        }
    }

    .ag-height-override-large{
        height: 70vh !important;
    }

    .ag-height-override-medium{
        height: 60vh !important;
    }