.contact-information-horizontal{
    display: flex !important;
    flex-direction: row !important; 
    justify-content: space-between !important;
    align-items: center !important;
    flex-wrap: wrap !important;
}

.contact-information-horizontal * {
    margin: 0 !important;
}

.contact-information-horizontal button {
    width: 45% !important;
}

.contact-information-horizontal .contact-info-p-wrapper{
    width: 45% !important;
}

.flex-row{
    display: flex !important;
}

.flex-row h3{
    padding-left: .5rem !important;
}