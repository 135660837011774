.justify-content-end {
  align-content: flex-end;
}
  
html {
  font-size: 12px;
}
@media (min-width: 768px) {
  html {
    font-size: 12px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navbar-container {
    padding-left: 1rem;
    padding-right: 1rem;
}

.navbar-nav {
  width: 100%;
  justify-content: flex-end;
}

a.navbar-brand {
  white-space:normal;
  text-align:center;
  word-break:break-all;
  margin:1em 3em 1em 1em;
}

a,a:hover,a:active {
  color:rgba(165200227/1)!important;
  }
  
a.nav-link {
font-size:14px;
padding:.5em 1em!important;
}