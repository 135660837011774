/* ------------------ ag-grid theme specific styling --------------------------------------------- */
.ag-theme-alpine {
  --ag-odd-row-background-color: #f0f0f0;
}

.ag-theme-alpine ::-webkit-scrollbar {
  width: 0.95em;
  height: 0.95em;
}

.ag-theme-alpine ::-webkit-scrollbar-track {
  background-color: transparent;
}

.ag-theme-alpine ::-webkit-scrollbar-thumb {
  background-color:#c4c4c4a9;
  border-radius: 10px;
  padding: 2px;
  background-clip: padding-box;
  border:2px solid transparent;
}

.ag-border-dark {
  --ag-row-border-color: black;
}

.ag-theme-balham {
    --ag-borders: none !important;
    --ag-header-background-color: rgb(226, 223, 223);
    --ag-header-height: 30px;
    --ag-odd-row-background-color: #f0f0f08e;
    --ag-row-hover-color: #4585fca9;
    --ag-checkbox-unchecked-color: gray;
    --ag-checkbox-checked-color: blue;
    --ag-selected-row-background-color: rgba(77, 107, 146, 0.767);
    width: 100% !important;
    height: 70vh !important;
}

.ag-theme-balham .ag-header {
  font-weight: bold;
}

.ag-header-group-cell {
    font-weight: bolder !important;
  }

.ag-theme-balham ::-webkit-scrollbar {
    width: 0.95em;
    height: 0.95em;
}

.ag-theme-balham ::-webkit-scrollbar-track {
    background-color: transparent;
}

.ag-theme-balham ::-webkit-scrollbar-thumb {
    background-color:#c4c4c4a9;
    border-radius: 10px;
    padding: 2px;
    background-clip: padding-box;
    border:2px solid transparent;
}

.ag-theme-balham-oap-types {
  --ag-borders: none !important;
  --ag-header-background-color: rgb(226, 223, 223);
  --ag-header-height: 30px;
  --ag-odd-row-background-color: #f0f0f08e;
  --ag-row-hover-color: #4585fca9;
  --ag-checkbox-unchecked-color: gray;
  --ag-checkbox-checked-color: blue;
  --ag-selected-row-background-color: rgba(0, 110, 255, 0.144);
  width: 100% !important;
  height: 100% !important;
}

.grid-height-mod {
  height: 70vh !important;
}

/* -------------------------------------------- Grid Specific Styling ----------------------- */
.invoice-number-highlight{
  background-color: #007df2 !important;
}

.negative-value {
  background-color: red;
}

.mismatched-row {
  background-color: var(--red) !important;
  color: var(--white) !important;
}

.mismatched-row:active,
.mismatched-row:focus,
.mismatched-row:focus-visible,
.mismatched-row:focus-within{
  color: black !important;
};


.pay-amount-highlight{
  background-color: #97bec58f !important;
}

.row-overlay {
  background-color: #67c2f7 !important;
}

.leftover-overlay {
  background-color: rgba(128, 128, 128, 0.582) !important;
  color: rgb(0, 0, 0);
}

.invoice-with-balance {
  background-color: rgb(253, 168, 70) !important;
}

.oap-with-balance {
  background-color: rgba(153, 80, 125, 0.603) !important;
}

.all-balances-zero {
  background-color: #1CBE1C !important;
}

.mismatch-warn {
  background-color: yellow !important;
}

.timecard-mismatch {
  background-color: orange !important;
}

/* -------------------------------------------- Button Themes -------------------------------- */
.acc-button {
  font-size: small !important;
  font-weight: 525 !important;
  font-family: 'Roboto' !important;
  padding: 2px 1px 2px, 1px !important;
  border-radius: 8px !important;
}

.aging-buttons {
  padding: 2px 1px 2px, 1px !important;
  border-radius: 5px !important;
  text-decoration: none !important;
  background-color: #0077CC;
  color: white !important;
  width: max-content !important;
  margin-right: .5rem;
  border: none !important;
}

.aging-buttons:hover{
  background-color: #0066AF;
}

.aging-buttons:active{
  transform: scale(0.98);
}

.card-header-button{
  font-size: small !important;
  padding: 2px 4px 0px 4px !important;
  background-color: var(--light-gray) !important;
  margin: 0px 5px 5px 0px;
  border: 1px solid silver !important;
}

.customer-id-toggle{
  margin: 0px 10px 5px 10px;
  padding: 5px 15px 5px 15px;
  background-color: #BCDFFB;
  border-radius: 10px;
  width: 225px;
  color: black;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}

.emailer-button-gapped {
  font-family: 'Roboto' !important;
  padding: 2px 1px 2px, 1px !important;
  border-radius: 8px !important;
  text-decoration: none !important;
  color: white !important;
  width: max-content !important;
  font-size: small !important;
  margin: 10px 10px 10px 10px !important;
}

.employer-toggle{
  margin: 0px 10px 5px 10px;
  padding: 5px 15px 5px 15px;
  background-color: #BCDFFB;
  border-radius: 10px;
  width: 135px;
  color: black;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}

.header-export-button {
  margin: 5px !important;
  padding: 5px !important;
  background-color: var(--green) !important;
  color: var(--white) !important;
  border-radius: 10px;
  border: none !important;
}

.header-export-button:hover {
  filter: contrast(125%) !important;
  filter: hue-rotate(20deg)!important;
}

.header-export-button:active {
  transform: scale(0.98) !important;
}

.link-button {
  background-color: #DFDFDF !important;
  padding: 2px 1px 2px, 1px !important;
  border-radius: 8px !important;
  text-decoration: none !important;
  color: black !important;
  width: max-content !important;
  margin: 10px 10px 10px 0px !important;
  font-size: small !important;
  font-weight: bolder !important;
  border-color: #DFDFDF !important;
}

.link-button-next {
  font-family: 'Roboto' !important;
  padding: 2px 1px 2px, 1px !important;
  border-radius: 8px !important;
  text-decoration: none !important;
  color: white !important;
  width: max-content !important;
  margin: 10px 10px 10px 0px !important;
  font-size: small !important;
}

.link-button-submit {
  font-family: 'Roboto' !important;
  padding: 2px 1px 2px, 1px !important;
  border-radius: 8px !important;
  text-decoration: none !important;
  color: white !important;
  width: max-content !important;
  margin: 10px 10px 10px 0px !important;
  font-size: small !important;
}

.module-button{
  background-color: transparent !important;
  border-radius: 5px !important;
  margin: .5px;
  color: black !important;
  border: 1px solid silver!important;
}
.module-button:hover{
  background-color: #BCDFFB !important;
}
.module-button:focus{
  background-color: #BCDFFB !important;
}

.standard-button {
  border: none !important;
  width: fit-content !important;
}
.standard-button:hover {
  filter: contrast(125%) !important;
  filter: hue-rotate(300deg)!important;
}

.standard-button:active{
  transform: scale(0.98);
}

.toggle-width-small{
  width: 100px !important;
  margin-right: 20px !important;
}

/* ---------------------------- Card Styles ----------------------------------------*/
.ats-card{
  min-height: 45vh;
  max-height: 55vh;
  border: 1px solid silver !important;
  align-items: center;
  align-content: center;
  margin: 5px 0px 5px 0px !important;
  padding: 10px;
  box-sizing: border-box;
  background-color: rgba(250, 250, 250, 0.65) !important;
  border-radius: 5px;
}

.cardHolder{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  margin: 5px 0px 5px 0px !important;
  width: 100%;
}

.compare-deck{
  display: grid;
  grid-template-columns: auto 500px;
  column-gap: 10px;
  margin: 5px 0px 5px 0px !important;
  width: 100%;
}

.compliance-advanced-card{
  min-height: 15vh;
  border: 1px solid silver !important;
  align-items: center;
  margin: 10px 0px 5px 0px;
  padding: 25px 25px 0px 25px;
  box-sizing: border-box;
  background-color: rgba(250, 250, 250, 0.65) !important;
  border-radius: 5px;
  display: grid;
  grid-template-rows: .5fr 1fr .5fr;
}

.error-note-card{
  display: flex !important;
  padding: 10px !important;
  margin: 5px !important;
  justify-content: center !important;
  width: 100%;
}

.flex-card-row{
  display: flex;
  flex-direction: row !important;
  flex-wrap: wrap !important;
  margin-top: 10px !important;
}

.header-card{
  min-height: 15vh;
  border: 1px solid silver !important;
  align-items: center;
  margin: 10px 0px 5px 0px;
  padding: 20px 20px 0px 20px;
  box-sizing: border-box;
  background-color: rgba(250, 250, 250, 0.65) !important;
  border-radius: 5px;
  display: grid;
  grid-template-rows: 1fr 1fr;
}

.header-card-auto{
  min-height: 15vh;
  border: 1px solid silver !important;
  align-items: center;
  margin: 10px 0px 5px 0px;
  padding: 20px 20px 0px 20px;
  box-sizing: border-box;
  background-color: rgba(250, 250, 250, 0.65) !important;
  border-radius: 5px;
  display: grid;
  grid-template-rows: auto auto;
}

.instructions-card{
  padding: 10px;
  margin: 5px !important;
  transition: all ease-out 1s;
  max-width: 99%;
}

.matching-deck{
  display: grid;
  grid-template-columns: 1fr 1.05fr;
  column-gap: 10px;
  margin: 0px !important;
}

.middle-card{
  height: 8vh;
  align-items: center;
  justify-content: center;
  margin: 10px;
  padding: 0px 20px 0px 20px;
  box-sizing: border-box;
  display: grid;
  height: 4rem;
}

.matching-card{
  height: 8vh;
  align-items: center;
  justify-content: center;
  margin: 5px 0px 5px 0px !important;
  padding: 0px 20px 0px 20px;
  box-sizing: border-box;
  background-color: rgba(250, 250, 250, 0.65) !important;
  border-radius: 5px;
  display: grid;
  height: 4rem;
  border: 1px solid silver;
  width: 100%;
  color: black !important;
}

.negative-vert-gap {
  margin-top: -5px !important;
}

.oap-card{
  min-height: 60vh;
  max-height: 67vh;
  border: 1px solid silver !important;
  align-items: center;
  align-content: center;
  margin: 5px 0px 5px 0px !important;
  padding: 10px;
  box-sizing: border-box;
  background-color: rgba(250, 250, 250, 0.65) !important;
  border-radius: 5px;
}

.roster-card {
  min-height: 70vh;
  max-height: 70vh;
  border: 1px solid silver !important;
  align-items: center;
  margin: 0px 10px 0px 10px;
  padding: 10px;
  box-sizing: border-box;
  background-color: rgba(250, 250, 250, 0.65) !important;
  border-radius: 5px;
}

.tall-card {
  min-height: 72vh;
  max-height: 80vh;
}

.top-card{
  min-height: 15vh;
  border: 1px solid silver !important;
  align-items: center;
  margin: 10px 0px 5px 0px;
  padding: 25px 25px 0px 25px;
  box-sizing: border-box;
  background-color: rgba(250, 250, 250, 0.65) !important;
  border-radius: 5px;
  display: grid;
  grid-template-rows: 1fr 1fr .75fr;
}

.vertical-card-fit {
  min-height: fit-content !important;
  border: 1px solid silver !important;
  align-items: center !important;
  align-content: center !important;
  margin: 5px 0px 5px 0px !important;
  padding: 10px;
  box-sizing: border-box;
  background-color: rgba(250, 250, 250, 0.65) !important;
  border-radius: 5px;
}

.vertical-card-shrink {
  max-height: 40vh !important;
}


/* ----------------------- color adjustments --------------------------*/
.blue-background {
  background-color: #097dcf  !important;
  color: var(--white);
}

.blue-text {
  color: #097dcf !important;
}

.green-background {
  background-color: var(--green) !important;
  color: white !important;
  font-weight: 600 !important;
}

.header-text {
  color: white !important;
}

.light-blue-background {
  background-color: #BCDFFB !important;
}

.medium-blue-background {
  background-color: #8ecafc !important;
}

.tab-color {
  background-color: #6c757d !important;
  color: var(--white);
}

.reconciled-background {
  border: none !important;
  background-color:var(--black) !important;
}

.reconciled-background:hover {
  background-color: var(--grey) !important;
}

.red-background {
  background-color: #DA4A4A!important;
  color: var(--white) 
}

.red-text {
  color:var(--red) !important;
}

.total-background {
  background-color: #6c757d!important;
  padding: 3px;
  border-radius: 3px;
}

.white-background {
  background-color: white !important;
  border: 1px solid silver !important;
}

.white-background-borderless {
  background-color: rgba(250, 250, 250, 0.65) !important;
}

.white-header-label {
  background-color: rgba(250, 250, 250, 0.65) !important;
  border: none;
  width: 100%;
  height: fit-content;
  padding: .25rem;
  display: flex;
  justify-content: center;
}

.white-left {
  background-color: rgba(250, 250, 250, 0.65) !important;
  border: none;
  width: 100%;
  height: fit-content;
  padding: .25rem;
}

/* --------------------- component styling -------------------------------*/

.color-info {
  padding: 5px;
  border: 2px solid black;
  border-radius: 5px;
}

.double-select{
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.four-column-div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 5px;
  margin: 10px 0px 10px 0px !important;
  width: 100%;
}

.grid-header {
  background-color: var(--light-gray);
  color: white;
  width: 100%;
  align-items: center;
  padding: 5px 10px 5px 10px;
  border-radius: 3px;
  margin: 5px;
  display: flex;
  justify-content: space-between;
  height: 3rem;
}

.hide-row {
  display: none;
}

.highlighted-box{
  background-color: #BCDFFB;
  width: 100%;
  height: 100%;
  padding: 8px;
  margin: 0px 10px 5px 10px;
  overflow-y: auto;
}

.date-input {
  width: 150px;
  padding-left: 5px;
  border-radius: 5px;
  margin: 0px 2.5px 0px 2.5px;
}

.silver-border {
  border: 1px solid silver;
}

.input-text-modal {
  width: 25vw !important;
}

.input-text-modal-small {
  width: 10vw !important;
}

.input-text-modal-xs {
  width: 2.5vw !important;
}

.inst-unchecked {
  color: transparent;
  padding-right: 5px;
}

.inst-checked {
  font-size: medium;
  color: var(--green) !important;
  transition: ease-in;
  padding-right: 5px;
}

.instruction-row {
  display: flex;
  flex-direction: row;
}

.form-row {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  width: fit-content;
  height: inherit;
}

.instruction-list {
  list-style-type: none;
}

.labelled-loader{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.totals-mismatch-warning{
  font-weight: bold;
  background-color: orange;
  padding: 2px;
  width: 100%;
  justify-content: center !important;
  display: flex;
}

.oap-nav{
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  border-bottom: 1px solid silver;
  margin-bottom: 5px;
}

.oap-totals{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 0px 10px 0px;
}
.oap-totals p {
  font-weight: bold;
}

.overflow-override{
  overflow-y: hidden !important;
}

.overflow-override-x {
  overflow-x: hidden !important;
}

.tc-compare {
  padding: 2px;
}

.tc-compare-header {
  font-weight: 500;
  padding: 3px;
}

.tc-totals-readout {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-content: center !important;
  align-content: center !important;
}

.tc-error-calced {
  background-color: #BCDFFB;
  padding: 5px;
  margin-right: 5px !important;
  border-radius: 5px;
}

.tc-error-tc {
  background-color: var(--grey);
  color: white;
  padding: 5px;
  margin: 0px 5px 0px 5px !important;
  border-radius: 5px;
}

.tc-error-calced {
  background-color: #BCDFFB;
  padding: 5px;
  margin-right: 5px !important;
  border-radius: 5px;
}

.tc-error-diff {
  padding: 5px;
  margin: 0px 5px 0px 5px !important;
  border-radius: 5px;
  background-color: transparent !important;
  color: black !important;
  border: 1px solid silver!important;
}

.three-column-div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  width: 100%;
}

.three-col-bsb{
  display: grid;
  grid-template-columns: 10fr .75fr 10fr;
  column-gap: 2px;
  width: 100%;
}

.two-col-head{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2px;
  width: 100%;
}

.two-col-even{
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5px;
  width: 100%;
}

.tooltip {
  pointer-events: none;
}

.module-info-modal ul {
  padding: 0px 0px 0px 30px !important;
  margin: 0 !important;
}

/* ----------------------------------- Emailer -------------------------------------------- */
.contact-type-text {
  border-bottom: 1px solid;
  border-color: #cecccc;
  width: 50px;
  padding: 5px 0px 1px 5px;
  margin-top:2px;
}

.bolded-info-text {
  font-weight: 600 !important
}

/* --------------------------------------- Page Layout Styling ------------------------------ */
.box {
  display: grid !important;
  width: 100vw !important;
  align-items: start !important;
  justify-content: space-between !important;
  left: 0;
}

.button-div {
  display: flex;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
}

.center-align {
  align-items: center;
  align-content: center;
  align-self: center;
}

.center-all{
  justify-items: center !important;
  justify-content: center !important;
  align-items: center !important;
  align-content: center !important;
}

.centered-all-box {
  position: relative;
  display: flex;
  align-items: center;
  text-justify: center;
  margin: 20px;
  text-align: center;
}

.center-justify {
  justify-items: center !important;
  justify-content: center !important;
  justify-self: center !important;
}

.disable-scroll {
  overflow: hidden !important;
}

.disable-scroll-x {
  overflow-x: hidden !important;
}

.div-horizontal-margin{
  margin: 0px 10px 0px 10px;
}

.div-vertical-margin{
  margin: 10px 0px 10px 0px !important;
}

.div-vertical-margin-large {
  margin: 20px 0px 20px 0px !important;
}

.div-vertical-padding {
  padding: 10px 0px 10px 0px !important;
}

.dropdown-styling {
  width: 40%;
}

.element-width {
  width: fit-content !important;
  flex-wrap: nowrap;
}

.empty-height {
  height: 25vh;
}

.full-width {
  width: 100% !important;
}

.grouped-input {
  display: grid;
  grid-template-columns: .35fr 1fr;
}


.justify-left {
  justify-content: left !important;
}

.justify-spaced {
  justify-content: space-between !important;
}

.label-gap{
  margin-right: 5px !important;
}

.left-align {
  display: flex !important;
  margin-left: 0 !important;
  justify-content: left !important;
  align-items: center !important;
}

.left-margin {
  margin-left: 1rem !important;
}

.margin-top{
  margin-top: .25rem;
}

.margin-bottom{
  margin-bottom: .25rem;
}

.right-align {
  display: flex !important;
  margin-right: 0 !important;
  justify-content: right !important;
  align-items: center !important;
}

.right-align-row {
  display: flex !important;
  flex-direction: row !important;
  margin-right: 0 !important;
  justify-content: right !important;
  align-items: center !important;
  width: 100% !important;
}

.row-gapper {
  margin-bottom: 5px !important;
}

.small-info-font {
  font-size: x-small !important;
  color: #727272 !important;
}

.scroll-on-overflow-y {
  overflow-y: auto;
}

.spaced {
  display:flex;
  justify-content: space-between;
  justify-items: center;
  align-content: center;
  align-items: center;
  margin: 10px 0px 10px 0px;
}

.grouped-buttons{
  display: flex;
  justify-content: center;
}

.text-padding {
  padding: 5px;
}

.text-pad-align-right {
  padding: 5px, 10px, 5px, 5px !important;
  text-align: right;
}

.totals-gap{
  margin: 0px 10px 0px 10px !important;
}

.wrap-overflow {
  flex-wrap: wrap;
}


/* ------------------------------------- Modal Styling ------------------------------------ */
.modal-header {
  /* background-color: #9ccdfc; */
  height: min-content;
}

.info-modal {
  min-height: 70vh !important;
  max-height: 70vh !important;
  min-width:70vw !important;
  max-width: 70vw !important;
  
}

.modal-body-content {
  max-height: calc(100vh - 200px);
  overflow-y: auto !important;
}

.modal-body-content::-webkit-scrollbar {
  width: 0.65em;
  height: 0.65em;
}

.modal-body-content::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 10px;
}

.modal-body-content::-webkit-scrollbar-thumb {
  background-color:#000000a9;
  border-radius: 10px;
  padding: 2px;
  background-clip: padding-box;
  border:2px solid transparent;
}

/* ------------------------------------ Other (HTML Table, etc.) ------------------------ */
.no-display {
  display: none;
}











