.compliance-group{
    display:flex;
}

.compliance{
    border: 1px solid #a1a1a1;
    display: flex;
    height: 25px;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border-radius: 10%;
}

.compliance-not-started{
    background-color: white;
}

.compliance-warning{
    background-color: red;
    color: white
}

.compliance-in-progress{
    background-color: rgb(255, 253, 121);
}

.compliance-complete{
    background-color: rgb(101, 241, 101);
}

.compliance-not-needed{
    background-color: grey;
    color: #e6e6e6;
}