.footer {
    height: 100px !important;
    position: absolute !important;
    bottom: -100px !important;
    left: 0 !important;
    width: 100% !important;
    background: #052540;
    color: #fff!important;
    display: flex;
    flex-direction: column;
    padding: 1rem !important;
}

.foot-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.foot-col {
  display: flex;
  flex-direction: column;
}