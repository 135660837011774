.advanced-search-button-group{
    margin-top: 1rem !important;
    display: flex;
    justify-content: flex-end;
}

.advanced-search-button-group > .btn-group{
    width: 100%;
}

@media (min-width: 768px ){
    .advanced-search-button-group{
        width: auto;
    }

    .advanced-search-button-group > .btn-group{
        width: auto;
    }
}