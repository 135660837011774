.sticky-nav-menu{
    position: sticky;
    top: 5vh;
}

.edit-button-wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.required-docs-grid{
    width: 100%;
    height: 50vh;
}

.vh20{
    height: 20vh !important;
}

.doc-sample-button {
  background: transparent;
  text-decoration: underline;
  border: none;
  color: blue;
}

.name-change {
  background-color: rgba(255, 242, 0, 0.25);
  border-radius: 5px;
  padding: 5px
}

.date-box {
  display: flex;
  flex-direction: column;
  margin: 0px 5px 0px 5px;
  justify-content: flex-start;
}

.top-card{
  min-height: 15vh;
  border: 1px solid silver !important;
  align-items: center;
  margin: 10px 0px 5px 0px;
  padding: 15px 15px 0px 15px;
  box-sizing: border-box;
  background-color: rgba(250, 250, 250, 0.65) !important;
  border-radius: 5px;
  display: grid;
  grid-template-rows: 1fr .5fr;
}

.date-select {
  margin: 2px 0px 2px 0px;
}

.form-row {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: center;
  width: 100%;
  height: inherit;
}

.search-row {
  display: flex;
  flex-direction: row;
  align-items: center !important;
}