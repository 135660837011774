.employee-view-checkbox{
    display: flex;
    align-items: center;
    justify-content: center;
}

.download-button{
    display: flex !important;
    align-items: flex-end !important;
    padding: 0 !important;
    margin: 0 !important;
    width: auto !important;
}

.overview-grey{
    background-color: rgb(202, 202, 202) !important;
}

.overview-orange{
    background-color: rgb(245, 255, 145) !important;
}

.overview-green{
    background-color: rgb(157, 255, 158) !important;
}