form label{
    width: 100%;
    text-align: center;
}

.candidate-search-form button{
    width: 100%;
    text-align: center;
}

.button-row{
    padding-top: 1rem;
}

.candidate-search-form{
    margin-bottom: 1rem;
}