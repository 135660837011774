.status-wrapper{
    height: 60vh !important;
    padding: 0.5rem;
    overflow-y: scroll;
    overflow-x: hidden;
}

.vh80{
    height: 80vh !important;
}

.update-status-button{
    margin-bottom: .5rem !important;
    width: 100%;
}

.contact-info-p-wrapper{
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
}